export const CREATE_CLASSIFIED_START = "CREATE_CLASSIFIED_START";
export const CREATE_CLASSIFIED_SUCCESS = "CREATE_CLASSIFIED_SUCCESS";
export const CREATE_CLASSIFIED_FAILURE = "CREATE_CLASSIFIED_FAILURE";

export const FETCH_USER_CLASSIFIED_START = "FETCH_USER_CLASSIFIED_START";
export const FETCH_USER_CLASSIFIED_SUCCESS = "FETCH_USER_CLASSIFIED_SUCCESS";
export const FETCH_USER_CLASSIFIED_FAILURE = "FETCH_USER_CLASSIFIED_FAILURE";
export const SET_CLASSIFIED_STATUS = "SET_CLASSIFIED_STATUS";

export const FETCH_CLASSIFIED_START = "FETCH_CLASSIFIED_START";
export const FETCH_CLASSIFIED_SUCCESS = "FETCH_CLASSIFIED_SUCCESS";
export const FETCH_CLASSIFIED_FAILURE = "FETCH_CLASSIFIED_FAILURE";

export const FETCH_LAST_CLASSIFIED_START = "FETCH_LAST_CLASSIFIED_START";
export const FETCH_LAST_CLASSIFIED_SUCCESS = "FETCH_LAST_CLASSIFIED_SUCCESS";
export const FETCH_LAST_CLASSIFIED_FAILURE = "FETCH_LAST_CLASSIFIED_FAILURE";

export const REMOVE_CLASSIFIED_START = "REMOVE_CLASSIFIED_START";
export const REMOVE_CLASSIFIED_SUCCESS = "REMOVE_CLASSIFIED_SUCCESS";
export const REMOVE_CLASSIFIED_FAILURE = "REMOVE_CLASSIFIED_FAILURE";

export const REGISTER_CLASSIFIED_START = "REGISTER_CLASSIFIED_START";
export const REGISTER_CLASSIFIED_SUCCESS = "REGISTER_CLASSIFIED_SUCCESS";
export const REGISTER_CLASSIFIED_FAILURE = "REGISTER_CLASSIFIED_FAILURE";

export const FETCH_CLASSIFIED_BY_STATUS_START =
  "FETCH_CLASSIFIED_BY_STATUS_START";
export const FETCH_CLASSIFIED_BY_STATUS_SUCCESS =
  "FETCH_CLASSIFIED_BY_STATUS_SUCCESS";
export const FETCH_CLASSIFIED_BY_STATUS_FAILURE =
  "FETCH_CLASSIFIED_BY_STATUS_FAILURE";

export const CLEAR_CLASSIFIED_STATE = "CLEAR_CLASSIFIED_STATE";

export const SET_CLASSIFIED_STATUS_START = "SET_CLASSIFIED_STATUS_START";
export const SET_CLASSIFIED_STATUS_SUCCESS = "SET_CLASSIFIED_STATUS_SUCCESS";
export const SET_CLASSIFIED_STATUS_FAILURE = "SET_CLASSIFIED_STATUS_FAILURE";

export const classifiedTypes = [
  { id: 0, name: "استخدام", color: "bg-blue-600", textColor: "text-white" },
  { id: 1, name: "اجاره", color: "bg-red-500", textColor: "text-white" },
  {
    id: 2,
    name: "خرید و فروش",
    color: "bg-yellow-300",
    textColor: "text-gray-800",
  },
  {
    id: 3,
    name: "سرویس و خدمات",
    color: "bg-gray-300",
    textColor: "text-gray-800",
  },
];

export const classifiedStatus = [
  "ذخیره",
  "در انتظار پرداخت",
  "",
  "پرداخت شده",
  "",
  "در انتظار تایید",
  "در نوبت چاپ",
  "عدم تایید",
];

import {
  USER_SIGN_IN_START,
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_IN_FAILURE,
  SAVE_USER_PROFILE_START,
  SAVE_USER_PROFILE_SUCCESS,
  SAVE_USER_PROFILE_FAILURE,
  SET_USER_STATE,
  CLEAR_USER
} from "./types";

const INITIAL_STATE = {
  isFetching: false,
  isSignedInSuccessfully: false,
  exist: true,
  profile: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case CLEAR_USER:
      return INITIAL_STATE;

    case SET_USER_STATE:
      return {
        ...state,
        uid: action.payload.uid,
        phoneNumber: action.payload.phoneNumber,
      };

    case USER_SIGN_IN_START:
      return {
        ...state,
        phoneNumber: action.payload.phoneNumber,
        uid: action.payload.uid,
        isSignedInSuccessfully: false,
        isFetching: true,
      };

    case USER_SIGN_IN_SUCCESS:
      return {
        ...state,
        profile: {...action.payload},
        isSignedInSuccessfully: true,
        isFetching: false,
      };

    case USER_SIGN_IN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSignedInSuccessfully: false,
        errorMessage: action.payload,
      };

    case SAVE_USER_PROFILE_START:
      return {
        ...state,
        profileToSave:action.payload,
        isSaving: true,
      };

    case SAVE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {...action.payload,status:1},
        isSaving: false,
      };

    case SAVE_USER_PROFILE_FAILURE:
      return {
        ...state,
        isSaving: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;

import {
  CREATE_CLASSIFIED_START,
  CREATE_CLASSIFIED_SUCCESS,
  CREATE_CLASSIFIED_FAILURE,
  FETCH_USER_CLASSIFIED_START,
  FETCH_USER_CLASSIFIED_SUCCESS,
  FETCH_USER_CLASSIFIED_FAILURE,
  FETCH_CLASSIFIED_START,
  FETCH_CLASSIFIED_SUCCESS,
  FETCH_CLASSIFIED_FAILURE,
  FETCH_LAST_CLASSIFIED_START,
  FETCH_LAST_CLASSIFIED_SUCCESS,
  FETCH_LAST_CLASSIFIED_FAILURE,
  REMOVE_CLASSIFIED_START,
  REMOVE_CLASSIFIED_SUCCESS,
  REMOVE_CLASSIFIED_FAILURE,
  REGISTER_CLASSIFIED_START,
  REGISTER_CLASSIFIED_SUCCESS,
  REGISTER_CLASSIFIED_FAILURE,
  CLEAR_CLASSIFIED_STATE,
  FETCH_CLASSIFIED_BY_STATUS_START,
  FETCH_CLASSIFIED_BY_STATUS_SUCCESS,
  FETCH_CLASSIFIED_BY_STATUS_FAILURE,
  SET_CLASSIFIED_STATUS_START,
  SET_CLASSIFIED_STATUS_SUCCESS,
  SET_CLASSIFIED_STATUS_FAILURE,
} from "./types";

export const clearClassifiedState = () => {
  return {
    type: CLEAR_CLASSIFIED_STATE,
  };
};

export const setClassifiedStatusStart = (status, classifiedId) => {
  return {
    type: SET_CLASSIFIED_STATUS_START,
    payload:{status, classifiedId},
  };
};

export const setClassifiedStatusSuccess = (status) => {
  return {
    type: SET_CLASSIFIED_STATUS_SUCCESS,
    payload: status,
  };
};

export const setClassifiedStatusFailure = (errorMessage) => {
  return {
    type: SET_CLASSIFIED_STATUS_FAILURE,
    payload: errorMessage,
  };
};

export const fetchClassifiedByStatusStart = (status) => {
  return {
    type: FETCH_CLASSIFIED_BY_STATUS_START,
    payload: status,
  };
};

export const fetchClassifiedByStatusSuccess = (classifieds) => {
  return {
    type: FETCH_CLASSIFIED_BY_STATUS_SUCCESS,
    payload: classifieds,
  };
};

export const fetchClassifiedByStatusFailure = (errorMessage) => {
  return {
    type: FETCH_CLASSIFIED_BY_STATUS_FAILURE,
    payload: errorMessage,
  };
};

export const registerClassifiedStart = (classifiedToRegister) => {
  return {
    type: REGISTER_CLASSIFIED_START,
    payload: classifiedToRegister,
  };
};

export const registerClassifiedSuccess = (classified) => {
  return {
    type: REGISTER_CLASSIFIED_SUCCESS,
    payload: classified,
  };
};

export const registerClassifiedFailure = (errorMessage) => {
  return {
    type: REGISTER_CLASSIFIED_FAILURE,
    payload: errorMessage,
  };
};

export const removeClassifiedStart = (classifiedId) => {
  return {
    type: REMOVE_CLASSIFIED_START,
    payload: classifiedId,
  };
};

export const removeClassifiedSuccess = () => {
  return {
    type: REMOVE_CLASSIFIED_SUCCESS,
  };
};

export const removeClassifiedFailure = (errorMessage) => {
  return {
    type: REMOVE_CLASSIFIED_FAILURE,
    payload: errorMessage,
  };
};

export const fetchLastClassifiedStart = (uid) => {
  return {
    type: FETCH_LAST_CLASSIFIED_START,
    payload: uid,
  };
};

export const fetchLastClassifiedSuccess = (classified) => {
  return {
    type: FETCH_LAST_CLASSIFIED_SUCCESS,
    payload: classified,
  };
};

export const fetchLastClassifiedFailure = (errorMessage) => {
  return {
    type: FETCH_LAST_CLASSIFIED_FAILURE,
    payload: errorMessage,
  };
};

export const fetchClassifiedStart = (classifiedId) => {
  return {
    type: FETCH_CLASSIFIED_START,
    payload: classifiedId,
  };
};

export const fetchClassifiedSuccess = (classified) => {
  return {
    type: FETCH_CLASSIFIED_SUCCESS,
    payload: classified,
  };
};

export const fetchClassifiedFailure = (errorMessage) => {
  return {
    type: FETCH_CLASSIFIED_FAILURE,
    payload: errorMessage,
  };
};

export const createClassifiedStart = (classified) => {
  return {
    type: CREATE_CLASSIFIED_START,
    payload: classified,
  };
};

export const createClassifiedSuccess = (classified) => {
  return {
    type: CREATE_CLASSIFIED_SUCCESS,
    payload: classified,
  };
};

export const createClassifiedFailure = (errorMessage) => {
  return {
    type: CREATE_CLASSIFIED_FAILURE,
    payload: errorMessage,
  };
};

export const fetchUserClassifiedsStart = () => {
  return {
    type: FETCH_USER_CLASSIFIED_START,
  };
};

export const fetchUserClassifiedsSuccess = (classifieds) => {
  return {
    type: FETCH_USER_CLASSIFIED_SUCCESS,
    payload: classifieds,
  };
};

export const fetchUserClassifiedsFailure = (errorMessage) => {
  return {
    type: FETCH_USER_CLASSIFIED_FAILURE,
    payload: errorMessage,
  };
};

import { useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import companyLogo from "../assets/images/logo.png";

import Pdf from "react-to-pdf";

export const PageNotFound = () => {
  return (
    <div className="grid place-items-center h-screen text-3xl">
      Invoice Not found.
    </div>
  );
};

function Invoice() {
  const ref = useRef();
  const { classifiedId } = useParams();

  const classified = useSelector(
    (state) => state.classified.list.filter((c) => c.id === classifiedId)[0]
  );

  const user = useSelector((state) => state.user.profile);

  const payer = classified.payment.paypal.payer;
  console.log(payer);

  const capture =
    classified.payment.paypal.purchase_units[0].payments.captures[0];

  const options = {
    orientation: "portrait",
    unit: "in",
    margin: 0,
  };



  if (!classified) return <PageNotFound />;

  return (
    <div className="w-full xl:w-10/12 2xl:w-10/12 flex flex-col items-end py-12 animatecss animatecss-fadeIn">
      <div className="grid grid-cols-1 lg:grid-cols-2 w-full px-5 border-b-2">
        <div className="text-center lg:text-left">
          <Pdf
            targetRef={ref}
            filename={`${user.firstName}-${
              user.lastName
            }-${new Date(capture.update_time).toLocaleDateString("en-CA")}.pdf`}
            options={options}
            x={.1}
            y={.1}
            scale={0.9}
            scaleFactor={30}
          >
            {({ toPdf }) => (
              <button
                type="button"
                className="hover:bg-gray-700 bg-black hover:text-gray-100 my-6  w-full lg:w-80
                              font-semibold text-white py-2 px-3 border border-gray-700 hover:border-transparent rounded"
                onClick={toPdf}
              >
                PDF دریافت نسخه
              </button>
            )}
          </Pdf>
        </div>
        <h1 className="text-3xl mt-2 py-6 font-bold text-center xl:text-right lg:order-last order-first">
          صورتحساب
        </h1>
      </div>
      <div
        className="w-full xl:w-7/12 2xl:w-7/12 flex flex-col items-end mx-auto px-5 "
        ref={ref}
      >
        <div className="grid grid-rows-1 w-full pr-0 xl:pr-20">
          <div className="flex flex-col  text-sm items-end w-full">
            <img src={companyLogo} className="w-40 mt-3" alt="" />
            <div className="mt-2 font-bold">Reference# : {capture.id}</div>
            <div className="pt-2 font-bold">
                Date:{" "}
                {new Date(capture.update_time).toLocaleDateString("en-CA")}
              </div>
          </div>
          <div className="grid grid-rows-1 w-full ">
            <div className="mt-5 text-sm ">
              <div>
                <p className="font-bold text-lg mb-2">Iran Javan Magazine</p>
                <div className="font-bold">HST#: 893146159</div>
                301- 6075 Yonge Street
                <div>North York ON M2M 3W2</div>
              </div>
              <div>Tel: 416-730-0203</div>
              <div>Fax: 416-730-1952</div>
            </div>
          </div>

          <div className="text-left grid grid-cols-1">
            <div></div>
            <div className="font-bold text-lg mt-5 ">
              <div>Billed To: {`${user.firstName} ${user.lastName}`}</div>

              <div className="font-bold">{user.email}</div>
             
            </div>
          </div>
        </div>

        <div className="w-full my-2 pr-0 xl:pr-20">
          <table className="mt-2 w-full border-t">
            <tbody>
              <tr>
                <td className="uppercase py-2">Description</td>
                <td className="font-bold">
                  Classified Ad to publish on magazine
                </td>
              </tr>
              <tr>
                <td className="uppercase py-2">Shipping method</td>
                <td className="font-bold">Service - no shipping</td>
              </tr>
              <tr>
                <td className="uppercase py-2">Total Cost</td>
                <td className="font-bold">
                  {(capture.amount.value / 1.13).toFixed(2)} CAD
                </td>
              </tr>
              <tr>
                <td className="uppercase py-2">HST</td>
                <td className="font-bold">
                  {(capture.amount.value - capture.amount.value / 1.13).toFixed(
                    2
                  )}
                  CAD
                </td>
              </tr>
              <tr>
                <td className="uppercase py-2 bg-gray-200">AMOUNT PAID</td>
                <td className="font-bold bg-gray-200">
                  {capture.amount.value} CAD
                </td>
              </tr>
            </tbody>
          </table>
          <p className="mt-5 text-md italic">
            Please kindly note that because of the nature of the service, all
            sales are final.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Invoice;

const Pricing = () => {
  return (
    <div
      className="w-full xl:w-10/12 2xl:w-10/12 px-5 py-12 text-xl  animatecss animatecss-fadeIn"
      style={{ direction: "rtl" }}
    >
      <div>
        <h1 className="text-3xl mt-2 py-6 font-bold text-center md:text-right">
          لیست هزینه
        </h1>
      </div>
      <hr />
      <table className="w-full xl:w-2/3 font-bold mt-5 shadow-sm border">
        <tbody>
          <tr>
            <td className="text-center p-5 border bg-gray-100 text-sm xl:text-xl">
              تعداد کلمات
            </td>
            <td className="text-center p-5 border bg-gray-100 text-sm xl:text-xl">
              یک شماره تلفن
            </td>
            <td className="text-center p-5 border bg-gray-100 text-sm xl:text-xl">
              دو شماره تلفن
            </td>
          </tr>
          <tr>
            <td className="text-right p-5 border  text-lg xl:text-xl">
               1 تا 15
            </td>
            <td className="p-5 text-center border">$10</td>
            <td className="p-5 text-center border">$20</td>
          </tr>
          <tr>
            <td className="text-right p-5 border  text-lg xl:text-xl">
               15 تا 30
            </td>
            <td className="p-5 text-center border">$20</td>
            <td className="p-5 text-center border">$40</td>
          </tr>
          <tr>
            <td className="text-right p-5 border  text-lg xl:text-xl">
               31 تا 50
            </td>
            <td className="p-5 text-center border">$30</td>
            <td className="p-5 text-center border">$60</td>
          </tr>
          <tr>
            <td className="text-right p-5 border  text-lg xl:text-xl">
               51 تا 80
            </td>
            <td className="p-5 text-center border">$60</td>
            <td className="p-5 text-center border">$120</td>
          </tr>
        </tbody>
      </table>

      <p className="text-right px-5 font-bold mt-5 text-sm xl:text-xl ">
         توجــــه:
      </p>
        
      <p className="text-right px-5 font-bold mt-2 text-sm xl:text-xl ">
        * در صورت  درج ایمیل در آگهی، مبلغ 5 دلار به هزینه آگهی اضافه خواهد شد.
      </p>

      <p className="text-right px-5 font-bold mt-2 text-sm xl:text-xl ">
        *هزینه ها به دلار کانادا و برای یک هفته است
      </p>
   
    </div>
  );
};

export default Pricing;

import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import useWindowSize from "../../hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "react-phone-number-input";


import Spinner from "../../components/Spinner";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "../../assets/css/ag-grid-custom.css";

import { classifiedStatus } from "../../redux/classified/types";
import { fetchClassifiedByStatusStart } from "../../redux/classified/actions";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const classifieds = useSelector((state) => state.classified.list);
  const user = useSelector((state) => state.user);

  const gridRef = useRef();
  const windowSize = useWindowSize();
  const navigate = useNavigate();


  const [orderType, setOrderType] = useState(5);
  const [columnDefs, setColumDef] = useState([]);

  useEffect(() => {
    if (windowSize.width <= 640) {
      return setColumDef([
        {
          field: "content",
          headerName: "متن آگهی",
          sortable: false,
        },
      ]);
    }

    setColumDef([
      {
        field: "updatedAt",
        headerName: "تاریــخ",
        maxWidth: 130,
        cellStyle: { direction: "ltr", textAlign: "left" },
        valueGetter: (params) =>
          `${new Date(params.data.updatedAt.seconds * 1000).toLocaleDateString(
            "EN-CA"
          )}`,
      },
      {
        field: "profile.phoneNumber",
        headerName: "تلفن",
        maxWidth: 165,
        cellStyle: { direction: "ltr", textAlign: "left" },
        valueGetter: (params) =>
          formatPhoneNumber(params.data.profile.phoneNumber),
      },
      {
        field: "content",
        headerName: "متن آگهی",
        sortable: false,
      },
      {
        field: "status",
        maxWidth: 120,
        headerName: "وضعیت",
        cellStyle: { fontSize: "90%" },
        valueGetter: (params) => classifiedStatus[params.data.status],
        sortable: false,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  useEffect(() => {
    dispatch(fetchClassifiedByStatusStart(parseInt(orderType)));
  }, [dispatch, orderType]);

  const defaultColDef = useMemo(() => {
    return {
      filter: false,
      sortable: true,
      floatingFilter: false,
      resizable: true,
      flex: 1,
      sortingOrder: ["desc", "asc"],
    };
  }, []);



  if (!user.profile)
      return null;

      
  return (
    <div
      className="w-full xl:w-10/12 2xl:w-10/12 px-5 py-10 mt-10 animatecss animatecss-fadeIn"
      style={{ direction: "rtl" }}
    >
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div>
          <h1 className="text-3xl pb-5  font-bold text-center md:text-right">
            سفارشات
          </h1>
        </div>
        <div className="w-full">
          <select
            id="countries"
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 
          focus:border-blue-500 block  p-2.5 float-left w-full lg:w-1/2 "
          onChange={(e)=>setOrderType(e.target.value)}
          >
            <option value="5">سفارشات جدید</option>
            <option value="6">سفارشات تایید شده</option>
            <option value="7">سفارشات تایید نشده</option>
          </select>
        </div>
      </div>
      <div></div>
      <div></div>

      <hr />
      {!classifieds.isFetching ? (
        <div className="ag-theme-alpine w-full mt-5 grid-h">
          <AgGridReact
            rowData={classifieds}
            columnDefs={columnDefs}
            enableRtl={true}
            ref={gridRef}
            animateRows
            onRowClicked={(row) => navigate(`/order-details/${row.data.id}`)}
            rowSelection={"single"}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationAutoPageSize={true}
            cacheQuickFilter={true}
          ></AgGridReact>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default AdminDashboard;

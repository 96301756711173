import { combineReducers } from "redux";

import userReducer from "./user/reducer";
import classifiedReducer from "./classified/reducer";

export const rootReducer = combineReducers({
    user:userReducer,
    classified:classifiedReducer
});



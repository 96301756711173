import React, { useState, useRef, useEffect } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  createClassifiedStart,
  fetchLastClassifiedStart,
} from "../redux/classified/actions";
import { classifiedTypes } from "../redux/classified/types";

const RegisterClassified = () => {
  const phoneRef = useRef();
  const dispatch = useDispatch();
  const maxWords = 80;

  const [phoneNumber1, setPhoneNumber1] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");
  const [duration, setDuration] = useState(1);
  const [wordCount, setWordCount] = useState(0);
  const [type, setType] = useState(0);

  const navigate = useNavigate();

  const classified = useSelector((state) => state.classified);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.uid) dispatch(fetchLastClassifiedStart(user.uid));
  }, [dispatch, user]);

  useEffect(() => {
    if (classified.status === 1 && classified.id) {
      navigate(`/preview-classified/${classified.id}`);
    }
  }, [classified, navigate]);

  const mySwal = withReactContent(Swal);

  const handleTextChange = (e) => {
    const wc = e.target.value.split(/[\s,=\-_\\]+/).filter(Boolean).length;
    setWordCount(wc);
    setContent(e.target.value);
  };

  const isContainPhoneNumber = (string) => {
    return string.match(/(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/img)?.length;
  };

  const isContainEmail = (text) => {
    var re =
      /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    return re.test(text);
  };

  const isValidEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )?.length;
  };

  const handleSaveAndPayClick = () => {
    const errors = [];

    if (
      (!phoneNumber1 && !email) ||
      (!isValidPhoneNumber(phoneNumber1 || "") && !isValidEmail(email))
    )
      errors.push(['لطفا" یک شماره تلفن و/یا آدرس ایمیل معتبر وارد کنید']);

    if (isContainPhoneNumber(content))
      errors.push(['لطفا" شماره تلفن را از متن آگهی حذف کنید']);

    if (!content || wordCount === 0 ) errors.push(["متن آگهی را وارد کنید"]);

    if (isContainEmail(content))
      errors.push(["لطفا ایمیل را از متن آگهی حذف کنید"]);

    if (wordCount > 80)
      errors.push([
        "امکان ثبت سفارش برای بیش از 80 کلمه بصورت آنلاین وجود ندارد ، لطفا با فروش تماس بگیرید",
      ]);

    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      return mySwal
        .fire({
          didOpen: () => {
            mySwal.clickConfirm();
          },
        })
        .then(async () => {
          await mySwal.fire({
            title: `<span style='color:red'>خطـــــا</span>`,
            customClass: {
              title: "text-red-500",
            },
            html: `<p align="right" style="color:red;font-size:17px">${errorMessage}</>`,
            icon: "error",
            showCancelButton: false,
            confirmButtonText: "باشه",
            confirmButtonColor: "red",
            allowOutsideClick: false,
            reverseButtons: true,
            closeOnCancel: true,
            target: document.getElementById("body"),
          });
        });
    }

    dispatch(
      createClassifiedStart({
        phoneNumber1: phoneNumber1 || "",
        phoneNumber2,
        email,
        content,
        duration,
        type,
        wordCount,
        status: 1,
      })
    );
  };

  return (
    !classified.isFetching && (
      <div className="flex flex-col items-start pt-16 min-h-screen py-0 px-5 lg:px-10 animatecss animatecss-fadeIn ">
        <div
          className="w-full xl:w-10/12 2xl:w-10/12  my-5"
          style={{ direction: "rtl" }}
        >
          <div className="text-center md:text-left mb-5">
            <h1 className="text-3xl  font-bold text-center md:text-right">
              ثبت آگهی نیازمندیها
            </h1>
          </div>
          <hr />
          <div className="flex flex-col lg:flex-row">
            {/* right side  */}
            <div className="w-full">
              <div className="mt-10 ">
                <label className="text-sm text-gray-500">نوع آگهی</label>
                <select
                  id="type"
                  className="form-select mt-2 w-full block h-12  px-3 py-1.5 mb-3 text-base font-normal text-gray-700 bg-white bg-clip-padding
                        bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  value={type}
                  style={{ direction: "rtl" }}
                >
                  {classifiedTypes.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </select>
                {/* ---------------------------------------------------------------------- */}

                <label className="text-sm text-gray-500">
                  نوبت چاپ آگهی و نشر الکترونیکی
                </label>
                <select
                  id="type"
                  className="form-select mt-2 w-full block h-12  px-3 py-1.5 mb-3 text-base font-normal text-gray-700 bg-white bg-clip-padding
                          bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  onChange={(e) => {
                    setDuration(e.target.value);
                  }}
                  value={duration}
                  style={{ direction: "rtl" }}
                >
                  <option value={1}>یک نوبت</option>
                  <option value={2}>دو نوبت</option>
                  <option value={3}>سه نوبت</option>
                  <option value={4}>چهار نوبت</option>
                </select>

                {/* ---------------------------------------------------------------------- */}
                <label className="text-sm text-gray-500">
                  شماره تلفن اول جهت درج در آگهی
                </label>
                <PhoneInput
                  className="mt-2 appearance-none  flex sm:3/6  relative w-full mb-3  px-4 py-3 border border-gray-300
              placeholder-gray-200 text-sm md:text-xl text-gray-900 rounded-md ase-in-out m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  style={{ direction: "ltr" }}
                  countries={["CA"]}
                  maxLength={14}
                  defaultCountry={"CA"}
                  value={phoneNumber1}
                  placeholder={"Phone number"}
                  ref={phoneRef}
                  onChange={setPhoneNumber1}
                  // onBlur={() => handlePhoneNumberBlur}
                  autoComplete="off"
                />

                {/* ---------------------------------------------------------------------- */}
                <label className="text-sm text-gray-500">
                  شماره تلفن دوم جهت درج در آگهی
                </label>
                <PhoneInput
                  className="mt-2 appearance-none  flex sm:3/6  relative w-full mb-3  px-4 py-3 border border-gray-300
              placeholder-gray-200 text-sm md:text-xl text-gray-900 rounded-md ase-in-out m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none disabled:bg-gray-200"
                  style={{ direction: "ltr" }}
                  countries={["CA"]}
                  disabled={!isValidPhoneNumber(phoneNumber1 || "")}
                  maxLength={14}
                  defaultCountry={"CA"}
                  value={phoneNumber2}
                  placeholder={"Phone number"}
                  ref={phoneRef}
                  onChange={setPhoneNumber2}
                  // onBlur={() => handlePhoneNumberBlur}
                  autoComplete="off"
                />

                {/* ---------------------------------------------------------------------- */}
                <label className="text-sm text-gray-500">
                  ایمیل جهت درج در آگهی
                </label>
                <input
                  type="email"
                  className="mt-2 appearance-none  flex  relative w-full mb-6  px-4 py-3 border border-gray-300
              placeholder-gray-200 text-sm md:text-xl text-gray-900 rounded-md ase-in-out m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  style={{ direction: "ltr" }}
                  placeholder="you@company.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            {/* Left side col */}
            <div className="w-full mt-10 px-0 lg:px-5">
              <label className="text-sm text-gray-500">
                متن آگهی را وارد کنید (حداکثر {maxWords} کلمه)
              </label>
              <textarea
                value={content}
                className=" form-control mt-2 text-right block w-full px-3 py-1.5 text-sm md:text-xl font-norma h-72  lg:h-96 text-gray-700
                 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0
                   focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="classifiedText"
                onChange={handleTextChange}
                onKeyPress={(e) =>
                  wordCount > maxWords ? e.preventDefault() : null
                }
              />
              <p>
                {wordCount}/{maxWords}
              </p>
              {wordCount > maxWords && (
                <span className="text-red-800 bg-red-200 rounded px-5">
                  توجه فرمایید اگر آگهی بیش از 80 کلمه است لطفا تماس بگیرید
                </span>
              )}
            </div>
          </div>
          <div className="w-full text-left px-0 md:px-5 pb-10">
            <button
              type="button"
              className="hover:bg-gray-700 w-full md:w-3/12 mt-5 px-5  bg-black py-2 hover:text-gray-100
                         font-semibold text-white pb-2  border border-gray-700 hover:border-transparent rounded"
              onClick={handleSaveAndPayClick}
            >
              پیش نمایش و پرداخت
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default RegisterClassified;

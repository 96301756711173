import "firebase/firestore";
import "firebase/storage";
import { firebaseConfig } from "./config";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

import { initializeApp } from "firebase/app";

import {
  getFirestore,
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  updateDoc,
  doc,
  // writeBatch,
  query,
  where,
  orderBy,
  getDoc,
  limit,
} from "firebase/firestore";

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const authentication = getAuth();
const db = getFirestore(app);


export const setClassifiedStatus= async (classifiedId,status) =>{
  const classifiedRef = doc(db, `classifieds/${classifiedId}`);
  await updateDoc(classifiedRef,{status});
  return status;
}




export const saveUserProfile = async (uid, profile) => {
  const today = new Date();
  if (profile.id) {
    const usersRef = doc(db, `/profiles/${profile.id}`);
    await updateDoc(usersRef, { ...profile, updatedAt: today, status: 0 });
    return profile;
  } else {
    const usersRef = collection(db, `/profiles/`);
    const newDoc = doc(usersRef);
    const profileToCreate = {
      ...profile,
      uid,
      createdAt: today,
      updatedAt: today,
      status: 1,
    };
    await setDoc(newDoc, profileToCreate);
    return { ...profileToCreate, id: newDoc.id };
  }
};

export const getUserProfile = async (uid) => {
  const usersRef = collection(db, `/profiles`);
  const eventQuery = query(
    usersRef,
    where("uid", "==", uid),
    orderBy("updatedAt", "desc", limit(1))
  );
  const profileSnapShot = await getDocs(eventQuery);

  return profileSnapShot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  })[0];
};

export const registerClassified = async (classified) => {
  const classifiedRef = doc(db, `/classifieds/${classified.id}`);
  const classifiedToUpdate = {
    id: classified.id,
    ...classified.payment,
    status: 5,
  };
  await updateDoc(classifiedRef, classifiedToUpdate);
  return { ...classified, classifiedToUpdate };
};

export const removeClassified = async (classifiedId) => {
  const classifiedRef = doc(db, `/classifieds/${classifiedId}`);
  await deleteDoc(classifiedRef);
};

export const fetchLastClassified = async (uid) => {
  const classifiedRef = collection(db, `/classifieds`);
  const eventQuery = query(
    classifiedRef,
    where("uid", "==", uid),
    orderBy("updatedAt", "desc", limit(1))
  );
  const classifiedSnapshot = await getDocs(eventQuery);

  return classifiedSnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  })[0];
};

export const fetchClassified = async (classifiedId) => {
  const classifiedRef = doc(db, `classifieds/${classifiedId}`);
  const classified = await getDoc(classifiedRef);
  return {
    ...classified.data(),
    exist: classified.exists(),
    id: classified.id,
  };
};

export const createClassified = async (user, classified) => {
  const today = new Date();
  const collectionRef = collection(db, `classifieds/`);
  const { id, firstName, lastName, phoneNumber, updatedAt } = user.profile;
  const newDoc = doc(collectionRef);
  const classifiedToSave = {
    ...classified,
    profile: {id, firstName, lastName, phoneNumber, updatedAt },
    uid: user.uid,
    createdAt: today,
    updatedAt: today,
  };
  await setDoc(newDoc, classifiedToSave);
  return { ...classifiedToSave, id: newDoc.id };
};

export const fetchUserClassifieds = async (uid) => {
  const classifiedRef = collection(db, `/classifieds`);
  const eventQuery = query(
    classifiedRef,
    where("uid", "==", uid),
    where("status", ">=", 3),
    orderBy("status", "desc"),
    orderBy("updatedAt", "desc")
  );
  const classifiedSnapshot = await getDocs(eventQuery);
  return classifiedSnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
};

export const fetchClassifiedsByStatus = async (status) => {
  const classifiedRef = collection(db, `/classifieds`);
  const eventQuery = query(
    classifiedRef,
    where("status", "==", status),
    orderBy("updatedAt")
  );
  const classifiedSnapshot = await getDocs(eventQuery);
  return classifiedSnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
};

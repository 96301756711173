import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "../hooks/useWindowSize";
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";

import { clearClassifiedState } from "../redux/classified/actions";
import { clearUser } from "../redux/user/actions";

const Sidebar = ({ isUserSignedIn }) => {
  const [showSidebar, setShowSidebar] = useState(true);
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const maxWidth = 1280;
  const logout = () => {
    getAuth()
      .signOut()
      .then(() => {
        dispatch(clearClassifiedState());
        dispatch(clearUser());
        setShowSidebar(windowSize.width >= maxWidth);
        console.log("SignOut Completed.");
      });
  };

  useEffect(() => {
    setShowSidebar(windowSize.width >= maxWidth);
  }, [windowSize]);

  return (
    isUserSignedIn && (
      <div>
        {showSidebar ? (
          <button
            className="flex xl:hidden text-5xl text-blue-600 font-thin items-center cursor-pointer fixed 
                right-4 top-0 z-50"
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12 mt-2 "
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        ) : (
          <svg
            onClick={() => setShowSidebar(!showSidebar)}
            className="fixed  xl:hidden  flex items-center cursor-pointer right-5 top-4 z-40"
            fill="#2563EB"
            viewBox="0 0 100 80"
            width="40"
            height="40"
          >
            <rect width="100" height="5"></rect>
            <rect y="25" x="13" width="100" height="5"></rect>
            <rect y="50" width="100" height="5"></rect>
          </svg>
        )}

        <div
          className={`top-16 right-0 w-2/3 sm:w-1/3 lg:w-3/12 xl:w-2/12 bg-gray-100  pt-10 px-3 ml-20 
            text-gray-800 fixed h-full z-40 shadow-md border ease-in-out duration-300 ${
              showSidebar ? "translate-x-0 " : "translate-x-full"
            }`}
        >
          <ul className="text-right text-lg">
            {user.profile?.role === "endUser" && (
              <li className="py-2">
                <button
                  className={`${
                    pathname === "/dashboard" ? "font-bold text-blue-700" : ""
                  }`}
                  onClick={() => {
                    setShowSidebar(windowSize.width >= maxWidth);
                    navigate("/dashboard");
                  }}
                >
                  آگهی های من
                </button>
              </li>
            )}
            {user.profile?.role === "admin" && (
              <li className="py-2">
                <button
                  className={`${
                    pathname === "/admin/dashboard" ? "font-bold text-blue-700" : ""
                  }`}
                  onClick={() => {
                    setShowSidebar(windowSize.width >= maxWidth);
                    navigate("/admin/dashboard");
                  }}
                >
                   سفارشات
                </button>
              </li>
            )}
          
            <li className="py-2">
              <button
                className={`${
                  pathname === "/profile" ? "font-bold text-blue-700" : ""
                }`}
                onClick={() => {
                  setShowSidebar(windowSize.width >= maxWidth);
                  navigate("/profile");
                }}
              >
                پروفایل کاربری
              </button>
            </li>

            {user.profile?.role === "endUser" && (
              <li className="py-2">
                <button
                  className={`${
                    pathname === "/register-classified" ||
                    pathname.includes("/preview-classified")
                      ? "font-bold text-blue-700 "
                      : ""
                  }`}
                  onClick={() => {
                    setShowSidebar(windowSize.width >= maxWidth);
                    navigate("/register-classified", {
                      state: { fromSidebar: true },
                    });
                  }}
                >
                  ثبت آگهی
                </button>
              </li>
            )}
            <li className="py-2">
              <button
                className={`${
                  pathname === "/pricing" ? "font-bold text-blue-700 " : ""
                }`}
                onClick={() => {
                  setShowSidebar(windowSize.width >= maxWidth);
                  navigate("/pricing", {
                    state: { fromSidebar: true },
                  });
                }}
              >
                لیست هزینه
              </button>
            </li>
            <li className="py-2 border-b border-gray-300"></li>
            <li className="pt-4">
              <button
                className={`${
                  pathname === "/support" ? "font-bold text-blue-700" : ""
                }`}
                onClick={() => {
                  setShowSidebar(windowSize.width >= maxWidth);
                  navigate("/support");
                }}
              >
                {" "}
                پشتیبانی
              </button>
            </li>
            <li className="py-2 border-b border-gray-300"></li>

            <li className="pt-4">
              <button
                className={`${
                  pathname === "/terms-of-use" ? "font-bold text-blue-700" : ""
                }`}
                onClick={() => {
                  setShowSidebar(windowSize.width >= maxWidth);
                  navigate("/terms-of-use");
                }}
              >
                {" "}
                قوانین وب سایت
              </button>
            </li>
            <li className="py-2 pt-4">
              <button
                className={`${
                  pathname === "/privacy-policy"
                    ? "font-bold text-blue-700"
                    : ""
                }`}
                onClick={() => {
                  setShowSidebar(windowSize.width >= maxWidth);
                  navigate("/privacy-policy");
                }}
              >
                {" "}
                حفظ حریم خصوصی
              </button>
            </li>

            <li className="py-2 border-b border-gray-300"></li>
            <li className="py-3">
              <button onClick={logout}>خـــروج</button>
            </li>
          </ul>
        </div>
      </div>
    )
  );
};

export default Sidebar;

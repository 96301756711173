
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/";

import { logger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

import { rootReducer } from "./root-reducer";
import rootSaga from "./root-saga";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

  

if (process.env.REACT_APP_MODE_ENV === "development") {
  middleware.push(logger);
}

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ['classified', 'user'],
};

const store = createStore(
  persistReducer(persistConfig, rootReducer),
  applyMiddleware(...middleware)
);

export const persistor = persistStore(store, { timeout: 1000 });

sagaMiddleware.run(rootSaga);

export default store;

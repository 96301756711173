import { all, call } from "redux-saga/effects";

import { userSagas } from "../redux/user/saga";
import { classifiedSagas } from "./classified/saga";


export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(classifiedSagas)
  ]
  );
}

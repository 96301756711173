import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUserClassifiedsStart } from "../redux/classified/actions";

import Spinner from "../components/Spinner";
import ClassifiedCard from "../components/ClassifiedCard";
import {classifiedStatus} from '../redux/classified/types';

const EndUserDashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const classifieds = useSelector((state) => state.classified);

  useEffect(() => {
    if (!classifieds.list?.length ) {
      dispatch(fetchUserClassifiedsStart());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.uid, classifieds.list]);

  if (!user.profile)
      return null;

  return !classifieds.isFetching ? (
    <div
      className="w-full xl:w-10/12 2xl:w-10/12 px-5 py-12 animatecss animatecss-fadeIn"
      style={{ direction: "rtl" }}
    >
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div>
          <h1 className="text-3xl mt-2 py-6 font-bold text-center md:text-right">
            آگهی های من
          </h1>
        </div>
        <div className="text-center lg:text-left">
          <button
            type="button"
            className="hover:bg-gray-700 bg-black hover:text-gray-100 my-6 w-full md:w-72
                                  font-semibold text-white py-2 px-2 border border-gray-700 hover:border-transparent rounded"
            onClick={() => navigate("/register-classified")}
          >
            <span className="text-xl">+</span>
            <span className="mx-2">ثبت آگهی در نیازمندیهای ایران جوان</span>
          </button>
        </div>
      </div>
      <hr />
      <div className="grid grid-flow-row auto-rows-max gap-5 pt-5 cursor-pointer">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  2xl:grid-cols-3 gap-5 ">
          {classifieds.list?.map((classified) => {
            return (
              <div
                key={classified.id}
                onClick={() => navigate(`/order-details/${classified.id}`)}
              >
                <div
                  className=" rounded  border border-gray-200 overflow-hidden 
                                        shadow-xl  bg-gray-50"
                >
                  <ClassifiedCard
                    classified={classified}
                    onClick={() => null}
                  />
                  <div className="grid grid-cols-1 px-5">
                    <div className="pt-4 pb-2 text-xs text-right">
                      آخرین وضعیت:
                      <span className={`inline-block border  rounded py-1 
                          text-sm font-semibold ${classified.status === 7 ? 'bg-red-200 text-red-800' :'bg-green-100 text-green-800'} mr-2 px-2`}>
                        {classifiedStatus[classified.status]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default EndUserDashboard;

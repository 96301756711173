import {
  CREATE_CLASSIFIED_START,
  CREATE_CLASSIFIED_SUCCESS,
  CREATE_CLASSIFIED_FAILURE,
  FETCH_USER_CLASSIFIED_START,
  FETCH_USER_CLASSIFIED_SUCCESS,
  FETCH_USER_CLASSIFIED_FAILURE,
  FETCH_CLASSIFIED_START,
  FETCH_CLASSIFIED_SUCCESS,
  FETCH_CLASSIFIED_FAILURE,
  FETCH_LAST_CLASSIFIED_START,
  FETCH_LAST_CLASSIFIED_SUCCESS,
  FETCH_LAST_CLASSIFIED_FAILURE,
  REMOVE_CLASSIFIED_START,
  REMOVE_CLASSIFIED_SUCCESS,
  REMOVE_CLASSIFIED_FAILURE,
  REGISTER_CLASSIFIED_START,
  REGISTER_CLASSIFIED_SUCCESS,
  REGISTER_CLASSIFIED_FAILURE,
  CLEAR_CLASSIFIED_STATE,
  FETCH_CLASSIFIED_BY_STATUS_START,
  FETCH_CLASSIFIED_BY_STATUS_SUCCESS,
  FETCH_CLASSIFIED_BY_STATUS_FAILURE,
  SET_CLASSIFIED_STATUS_START,
  SET_CLASSIFIED_STATUS_SUCCESS,
  SET_CLASSIFIED_STATUS_FAILURE,
} from "./types";

const INITIAL_STATE = {
  isFetching: false,
  status: null,
  content: "",
  phoneNumber1: "",
  phoneNumber2: "",
  email: null,
  duration: null,
  payment: null,
  uid: null,
  errorMessage: null,
  createdAt: null,
  updatedAt: null,
  confirmed:false
};

const classifiedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_CLASSIFIED_STATE:
      return {
        ...state,
        ...INITIAL_STATE,
        list: [],
      };
    case FETCH_CLASSIFIED_BY_STATUS_START:
      return {
        ...state,
        list:[],
        confirmed:false,
        requestedStatus: action.payload,
        isFetching: true,
      };

    case FETCH_CLASSIFIED_BY_STATUS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        errorMessage: null,
        isFetching: false,
      };

    case FETCH_CLASSIFIED_BY_STATUS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };

    case REGISTER_CLASSIFIED_START:
      return {
        ...state,
        classifiedToRegister: action.payload,
        isRegistering: true,
      };

    case REGISTER_CLASSIFIED_SUCCESS:
      return {
        ...state,
        ...action.payload,
        errorMessage: null,
        list: [],
      };

    case REGISTER_CLASSIFIED_FAILURE:
      return {
        ...state,
        isRegistering: false,
        errorMessage: action.payload,
      };

    case REMOVE_CLASSIFIED_START:
      return {
        ...state,
        id: action.payload,
        isRemoving: true,
      };

    case REMOVE_CLASSIFIED_SUCCESS:
      return {
        ...INITIAL_STATE,
        isRemoving: false,
        errorMessage: null,
      };

    case REMOVE_CLASSIFIED_FAILURE:
      return {
        ...state,
        isRemoving: false,
        errorMessage: action.payload,
      };
    case FETCH_CLASSIFIED_START:
      return {
        ...state,
        id: action.payload,
        isFetching: true,
      };

    case FETCH_CLASSIFIED_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        errorMessage: null,
      };

    case FETCH_CLASSIFIED_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };

    case FETCH_LAST_CLASSIFIED_START:
      return {
        ...state,
        uid: action.payload,
        isFetching: true,
      };

    case FETCH_LAST_CLASSIFIED_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        errorMessage: null,
      };

    case FETCH_LAST_CLASSIFIED_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };

    case CREATE_CLASSIFIED_START:
      return {
        ...state,
        classifiedToCreate: action.payload,
        isCreating: true,
      };

    case CREATE_CLASSIFIED_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isCreating: false,
        confirmed:false,
        errorMessage: null,
      };

    case CREATE_CLASSIFIED_FAILURE:
      return {
        ...state,
        isCreating: false,
        errorMessage: action.payload,
      };

    case FETCH_USER_CLASSIFIED_START:
      return {
        ...state,
        uid: action.payload,
        isFetching: true,
      };

    case FETCH_USER_CLASSIFIED_SUCCESS:
      return {
        ...state,
        list: action.payload.length ? action.payload : null,
        isFetching: false,
        errorMessage: null,
      };

    case FETCH_USER_CLASSIFIED_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };

    case SET_CLASSIFIED_STATUS_START:
      return {
        ...state,
        classifiedToUpdate: {status:action.payload.status, id:action.payload.classifiedId},
        isFetching: true,
        confirmed:false,
      };

    case SET_CLASSIFIED_STATUS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        confirmed:true,
      };

    case SET_CLASSIFIED_STATUS_FAILURE:
      return {
        ...state,
        uid: action.payload,
        isFetching: true,
      };

    default:
      return state;
  }
};

export default classifiedReducer;

const Loader = () => {
  return (
    <div
      className="w-full xl:w-10/12 2xl:w-10/12 fixed top-0 left-0 xl:left-12 2xl:left-16 right-0 bottom-0 h-screen z-50 overflow-hidden 
          bg-transparent opacity-75 flex flex-col items-center justify-center"
    >
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-400 h-16 w-16 mb-2"></div>
      <h2 className="text-center text-gray-700 text-sm font-thin">دریافت اطلاعات</h2>
      <p className="text-center text-gray-700 text-xs">
         لطفا چند لحظه صبر کنید صفحه در حال بارگذاری است
      </p>
    </div>
  );
};
export default Loader;

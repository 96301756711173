import {
  USER_SIGN_IN_START,
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_IN_FAILURE,

  SAVE_USER_PROFILE_START,
  SAVE_USER_PROFILE_SUCCESS,
  SAVE_USER_PROFILE_FAILURE,
  
  SET_USER_STATE,
  CLEAR_USER,

} from "./types";




export const clearUser = (profile)=>{
  return {
     type: CLEAR_USER,
   };
 }
 

export const saveUserProfileStart = (profile)=>{
 return {
    type: SAVE_USER_PROFILE_START,
    payload: profile,
  };
}



export const saveUserProfileSuccess = (profile)=>{
  return {
     type: SAVE_USER_PROFILE_SUCCESS,
     payload: profile,
   };
 }
 

 export const saveUserProfileFailure = (errorMessage) => {
  return {
    type: SAVE_USER_PROFILE_FAILURE,
    payload: errorMessage,
  };
};

export const setUserState = (user)=>{
  return {
    type: SET_USER_STATE,
    payload: user,
  };
}


export const userSignInStart = (user) => {
  return {
    type: USER_SIGN_IN_START,
    payload: user,
  };
};

export const userSignInSuccess = (profile) => {
  return {
    type: USER_SIGN_IN_SUCCESS,
    payload: profile,
  };
};

export const userSignInFailure = (errorMessage) => {
  return {
    type: USER_SIGN_IN_FAILURE,
    payload: errorMessage,
  };
};

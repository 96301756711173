import { useState } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";


import { registerClassifiedStart } from "../redux/classified/actions";

const PaypalCheckoutButton = ({ product, agreed }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);

  const classified = useSelector((state) => state.classified);

  const handleApprove = (data) => {
    // set classified status to 2
    setPaidFor(true);

    navigate("/dashboard");
    // refresh teh user account
    //if the response return error will notify user
    //setError('Not worked');
  };



  if (paidFor && !error) {
    // redirect user
    alert("Payment was successfully");
  }

  if (error) {
    //display error message / redirect to error page
    console.log(error);
  }

  return (
    <div>
      <PayPalButtons
        disabled={!agreed || !product.price}
        style={{
          height: 54,
          layout: "horizontal",
          tagline: false,
        }}
        onClick={(data, actions) => {
          return actions.resolve();
        }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: product.description,
                amount: { value: product.price },
              },
            ],
          });
        }}
      
        onApprove={async (data, actions) => {
          const paypal = await actions.order.capture();
          const payment = { payment: { paypal } };
          const {
            id,
            phoneNumber1,
            phoneNumber2,
            email,
            content,
            duration,
            type,
          } = classified;
          dispatch(
            registerClassifiedStart({
              id,
              phoneNumber1,
              phoneNumber2,
              email,
              content,
              duration,
              type,
              payment,
            })
          );

          handleApprove(data);
        }}
        
        onCancel={() => {
          //back to cart
        }}
        onError={(err) => {
          setError(err);
          console.log(err);
        }}
      />
    </div>
  );
};

export default PaypalCheckoutButton;

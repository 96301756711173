import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import LoginPage from "./pages/LoginPage";
import Dashboard from "./pages/Dashboard";
import RegisterClassified from "./pages/RegisterClassified";

import Sidebar from "./components/Sidebar";
import Header from "./components/Header";

import PreviewClassified from "./pages/PreviewClassified";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import OrderDetails from "./pages/OrderDetails";
import Pricing from "./pages/Pricing";
import Invoice from "./pages/Invoice";
import TermsOfUse from "./pages/TermsOfUse";
import Profile from "./pages/Profile";
import Support from "./pages/Support";
import AdminDashboard from "./pages/admin/AdminDashboard";

import { userSignInStart, clearUser } from "./redux/user/actions";



function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isUserSignedIn, setIsUserSignedIn] = useState(null);

  const user = useSelector((state) => state.user);

  const PageNotFound = () => {
    if ( !isUserSignedIn)
      return null;
    return (
      <div className="grid place-items-center h-screen text-3xl">
        Page Not found.
      </div>
    );
  };


  useEffect(() => {
    if (user.profile)
      if (user.uid && !user.profile?.status) {
        navigate("/profile", { state: { message: "No profile" } });
      }
  }, [navigate, user]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsUserSignedIn(true);
        dispatch(userSignInStart(user));
      } else {
        dispatch(clearUser());
        navigate("/login");
        setIsUserSignedIn(false);
      }
    });
  }, [dispatch, navigate]);

  return (
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "CAD",
      }}
    >
      <div className="App">
        <Header isUserSignedIn={isUserSignedIn} />
        <Sidebar isUserSignedIn={isUserSignedIn} />


        <Routes>
          <Route
            path="/Login"
            element={
              isUserSignedIn && user.profile?.role === "endUser" ? (
                <Navigate replace to={"/dashboard"} />
              ) : isUserSignedIn && user.profile?.role === "admin" ? (
                <Navigate replace to={"/admin/dashboard"} />
              ) : (
                <LoginPage />
              )
            }
          />

          <Route path="/login" element={<LoginPage />} />
          {user.profile?.role === "endUser" && (
            <Route path="/dashboard" element={<Dashboard />} />
          )}
          {user.profile?.role === "admin" && (
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
          )}
          <Route path="/support" element={<Support />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
          <Route
            path="/order-details/:classifiedId"
            element={<OrderDetails />}
          />
          <Route
            path="/"
            element={
              <Navigate
                replace
                to={
                  user.profile?.role === "admin"
                    ? "/admin/dashboard"
                    : "/dashboard"
                }
              />
            }
          />
          <Route
            path="/preview-classified/:classifiedId"
            element={<PreviewClassified />}
          />
          <Route path="/register-classified" element={<RegisterClassified />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/invoice/:classifiedId" element={<Invoice />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </PayPalScriptProvider>
  );
}

export default App;

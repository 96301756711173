import React, { useState, useRef } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
import portalImage from "../assets/images/Scenes04.svg";
import { useDispatch } from "react-redux";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  browserSessionPersistence,
  setPersistence,
} from "firebase/auth";
import { authentication } from "../firebase/utils";
import { clearClassifiedState } from "../redux/classified/actions";

const LoginPage = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [page, setPage] = useState(1);
  const [error, setError] = useState("");
  const [recaptchaConfirmed, setRecaptchaConfirmed] = useState(false);

  const dispatch = useDispatch();
  const phoneRef = useRef();
  const otpRef = useRef();

  const handleOtpChange = (event) => {
    if (event.target.value.length <= 6) setOtp(event.target.value);
  };

  const handlePhoneNumberBlur = () => {
    if (
      !isValidPhoneNumber(phoneNumber) ||
      (phoneNumber.length !== 0 &&
        phoneNumber !== "+14444444444" &&
        phoneNumber !== "+12222222222")
    )
      setError("لطفا یک شماره تلفن همراه معتبر وارد کنید");
    phoneRef.current.focus();
  };

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-holder",
      {
        size: "invisible",
        callback: (response) => {
          setError("");
          setRecaptchaConfirmed(true);
          setTimeout(() => otpRef.current.focus(), 300);
        },
      },
      authentication
    );
  };

  const confirmOTP = () => {
    otpRef.current.focus();

    if (otp.length !== 6)
      return setError("کد تایید صحیح نیست. مجددا سعی نمایید");

    if (!recaptchaConfirmed)
      return setError("لطفا صفحه را ریفرش کنید و مجددا سعی کنید.");

    if (!window.confirmationResult) return setError("Code is not correct");

    let confirmationResult = window.confirmationResult;

    dispatch(clearClassifiedState());
    confirmationResult
      .confirm(otp)
      .then((result) => {})
      .catch((error) => {
        setError("کد تایید صحیح نیست. مجددا سعی نمایید");
      });
  };

  const handleVerifyPhone = () => {
    if (!phoneNumber) return setError("لطفا  یک شماره تلفن معتبر وارد کنید");
    if (!error && phoneNumber?.length === 12) {
      setPage(2);
      setOtp("");
      generateRecaptcha();
      const appVerifier = window.recaptchaVerifier;
      // signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
      //   .then((confirmationResult) => {
      //     window.confirmationResult = confirmationResult;
      //   })
      //   .catch((error) => {
      //     console.log(error.message);
      //     if (error.message.includes("too-many-requests"))
      //       setError(
      //         `تعداد دریافت کد تایید زیاد است یک ساعت صبر کنید و مجددا سعی نمایید`
      //       );
      //     else
      //       setError(
      //         `شماره تلفن وارد شده معتبر نیست، لطفا  شماره همراه معتبر وارد کنید`
      //       );
      //   });

      // logout when browser close
      setPersistence(authentication, browserSessionPersistence).then(
        async () => {
          try {
            const confirmationResult = await signInWithPhoneNumber(
              authentication,
              phoneNumber,
              appVerifier
            );
            window.confirmationResult = confirmationResult;
          } catch (error) {
            setError(
              `شماره تلفن وارد شده معتبر نیست، لطفا  شماره همراه معتبر وارد کنید`
            );
            setPage(1);
          }
        }
      );
    }
  };

  const renderErrors = () => {
    return (
      <div>
        {error && (
          <div className="bg-red-100 p-3 mt-3 text-sm md:text-lg text-red-600 rounded-md font-bold text-right mb-5 ">
            {error}
          </div>
        )}
      </div>
    );
  };

  // const reverse=(str)=>[...str].reverse().join('');

  const OTPInputForm = () => {
    return (
      <div className="flex flex-col justify-center items-end w-full">
        <div className="mb-16">
          <h1 className="text-left text-lg mb-10" style={{ direction: "ltr" }}>
            کد تایید به شماره {phoneNumber.replace("+1", "")} ارسال شد
          </h1>
          {renderErrors()}
          <input
            type="text"
            inputMode="numeric"
            ref={otpRef}
            className="mt-1 appearance-none  relative block w-full px-4 py-3 border border-gray-300 
           placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-200 
           focus:border-indigo-200 focus:z-10 text-2xl"
            autoFocus
            value={otp}
            placeholder="PIN"
            onKeyPress={(event) => {
              setError("");
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={handleOtpChange}
          />
        </div>
        <div className="w-full md:w-60">
          <button
            className="text-center w-full px-5 py-4 bg-blue-600 text-white rounded-md text-xl
            hover:bg-blue-500 hover:drop-shadow-md duration-300 ease-in"
            type="button"
            onClick={confirmOTP}
          >
            ورود
          </button>
        </div>
      </div>
    );
  };

  const PhoneInputForm = () => {
    return (
      <div>
        <h1 className="text-right text-text-lg mb-10 ">
          {`جهت ورود به سایت شماره تلفن همراه خود را وارد کنید و روی دکمه ادامه کلیک کنید. کد تایید برای شما ارسال خواهد شد`}
        </h1>
        {renderErrors()}
        <div className="flex flex-col justify-center items-end">
          <div className="mb-16 w-full lg:w-96">
            <PhoneInput
              className="mt-1   appearance-none  relative block w-full px-4 py-3 border border-gray-300 
                                            placeholder-gray-200 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-200 
                                                focus:border-indigo-200 focus:z-10 text-2xl"
              countries={["CA"]}
              maxLength={14}
              defaultCountry={"CA"}
              value={phoneNumber || ""}
              autoFocus
              placeholder={"Phone number"}
              ref={phoneRef}
              onChange={setPhoneNumber}
              onBlur={() => handlePhoneNumberBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                setError("");
              }}
            />
          </div>
          <div className="w-full md:w-60">
            <button
              className="text-center w-full px-20 py-4 bg-blue-600 text-white rounded-md text-xl
                                         hover:bg-blue-500 hover:drop-shadow-md duration-300 ease-in"
              type="button"
              onClick={handleVerifyPhone}
            >
              ادامه
            </button>
          </div>
        </div>
      </div>
    );
  };

  const Page = ({ page }) => {
    switch (page) {
      case 1:
        return <PhoneInputForm />;
      case 2:
        return <OTPInputForm />;
      default:
        return null;
    }
  };

  return (
    <section className="h-screen ">
      <div id="recaptcha-holder"></div>
      <div className="px-5 h-full text-gray-800">
        <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full">
          <div className="grow-0 shrink-1 md:shrink-0 basis-auto w-full xl:w-6/12 lg:w-6/12 md:w-9/12 mt-10 md:mb-0">
            <img src={portalImage} className="w-full" alt="" />
          </div>
          <div className="xl:ml-20 xl:w-12/12 lg:w-5/12 md:w-8/12 mb-10 md:mb-0">
            <div
              className="p-5 bg-white rounded-xl drop-shadow-md border space-y-5 mb-10"
              action=""
            >
              <h1 className="text-center text-xl lg:text-4xl font-bold">
                {`ورود به پورتال ایران جوان`}
              </h1>
              <hr />
              <Page page={page} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;

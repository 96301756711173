import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { USER_SIGN_IN_START, SAVE_USER_PROFILE_START } from "./types";

import {
  userSignInSuccess,
  userSignInFailure,
  saveUserProfileSuccess,
  saveUserProfileFailure,
} from "./actions";

import { getUserProfile, saveUserProfile } from "../../firebase/utils";

const getUser = (state) => {
  return state.user;
};

export function* saveUserProfileStart() {
  yield takeLatest(SAVE_USER_PROFILE_START, saveUserProfileStartAsync);
}

export function* saveUserProfileStartAsync() {
  const user = yield select(getUser);

  try {
    const profile = yield call(saveUserProfile, user.uid, user.profileToSave);
    yield put(saveUserProfileSuccess(profile));
  } catch (error) {
    yield put(saveUserProfileFailure(error.message));
  }
}

export function* userSignInStart() {
  yield takeLatest(USER_SIGN_IN_START, userSignInStartAsync);
}

export function* userSignInStartAsync() {
  const user = yield select(getUser);
  try {
    const profile = yield call(getUserProfile, user.uid);
    yield put(userSignInSuccess({...profile,status: profile?.uid? 1:0}));
  } catch (error) {
    yield put(userSignInFailure(error.message));
  }
}

export function* userSagas() {
  yield all([call(userSignInStart), call(saveUserProfileStart)]);
}

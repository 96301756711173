export const USER_SIGN_IN_START='USER_SIGN_IN_START';
export const USER_SIGN_IN_SUCCESS= 'USER_SIGN_IN_SUCCESS';
export const USER_SIGN_IN_FAILURE='USER_SIGN_IN_FAILURE';
export const SET_USER_STATE = "SET_USER_STATE";
export const CLEAR_USER = 'CLEAR_USER';


export const SAVE_USER_PROFILE_START="SAVE_USER_PROFILE_START";
export const SAVE_USER_PROFILE_SUCCESS="SAVE_USER_PROFILE_SUCCESS";
export const SAVE_USER_PROFILE_FAILURE="SAVE_USER_PROFILE_FAILURE";



import { useNavigate } from "react-router-dom";
import companyLogo from "../assets/images/logo.png";

const Header = (isUserSignedIn) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="h-16 w-full bg-gray-50 fixed top-0 shadow border-b border-gray-200 z-40">
        <img
          src={companyLogo}
          className="w-32 absolute py-2 m-auto xl:m-0  left-0 xl:left-auto  right-0 xl:right-10
                opacity-90 hover:cursor-pointer"
          alt=""
          onClick={() => {
            if (isUserSignedIn) navigate("/dashboard");
          }}
        />
      </div>
    </div>
  );
};

export default Header;

//15 = $10
//30= $20
//30-50= $30
//+ tax

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import {
  CREATE_CLASSIFIED_START,
  FETCH_USER_CLASSIFIED_START,
  FETCH_CLASSIFIED_START,
  FETCH_LAST_CLASSIFIED_START,
  REMOVE_CLASSIFIED_START,
  REGISTER_CLASSIFIED_START,
  FETCH_CLASSIFIED_BY_STATUS_START,
  SET_CLASSIFIED_STATUS_START,
} from "./types";

import {
  createClassifiedSuccess,
  createClassifiedFailure,
  fetchUserClassifiedsSuccess,
  fetchUserClassifiedsFailure,
  fetchClassifiedSuccess,
  fetchClassifiedFailure,
  fetchLastClassifiedSuccess,
  fetchLastClassifiedFailure,
  removeClassifiedSuccess,
  removeClassifiedFailure,
  registerClassifiedSuccess,
  registerClassifiedFailure,
  fetchClassifiedByStatusSuccess,
  fetchClassifiedByStatusFailure,
  setClassifiedStatusSuccess,
  setClassifiedStatusFailure,
} from "./actions";

import {
  createClassified,
  fetchUserClassifieds,
  fetchClassified,
  fetchLastClassified,
  removeClassified,
  registerClassified,
  fetchClassifiedsByStatus,
  setClassifiedStatus,
} from "../../firebase/utils";

const getClassified = (state) => {
  return state.classified;
};

const getClassifiedToCreate = (state) => {
  return state.classified.classifiedToCreate;
};

const geRequestedStatus = (state) => {
  return state.classified.requestedStatus;
};

const getClassifiedToUpdate = (state) => {
  return state.classified.classifiedToUpdate;
};

const getClassifiedToRoister = (state) => {
  return state.classified.classifiedToRegister;
};

const getUID = (state) => {
  return state.user.uid;
};

const getUser = (state) => {
  return state.user;
};

export function* setClassifiedStatusStart() {
  yield takeLatest(SET_CLASSIFIED_STATUS_START, fetchClassifiedByStatusAsync);
}

export function* fetchClassifiedByStatusAsync() {
  const classifiedToUpdate = yield select(getClassifiedToUpdate);
  try {
    const classified = yield call(setClassifiedStatus, classifiedToUpdate.id, classifiedToUpdate.status);
    yield put(setClassifiedStatusSuccess(classified));
  } catch (error) {
    yield put(setClassifiedStatusFailure(error.message));
  }
}

export function* fetchClassifiedByStatusStart() {
  yield takeLatest(
    FETCH_CLASSIFIED_BY_STATUS_START,
    fetchClassifiedByStatusStartAsync
  );
}

export function* fetchClassifiedByStatusStartAsync() {
  const requestedStatus = yield select(geRequestedStatus);
  try {
    const classified = yield call(fetchClassifiedsByStatus, requestedStatus);
    yield put(fetchClassifiedByStatusSuccess(classified));
  } catch (error) {
    yield put(fetchClassifiedByStatusFailure(error.message));
  }
}

export function* registerClassifiedStart() {
  yield takeLatest(REGISTER_CLASSIFIED_START, registerClassifiedStartAsync);
}

export function* registerClassifiedStartAsync() {
  const classifiedToRoister = yield select(getClassifiedToRoister);
  try {
    const classified = yield call(registerClassified, classifiedToRoister);
    yield put(registerClassifiedSuccess(classified));
  } catch (error) {
    yield put(registerClassifiedFailure(error.message));
  }
}

export function* removeClassifiedStart() {
  yield takeLatest(REMOVE_CLASSIFIED_START, removeClassifiedStartAsync);
}

export function* removeClassifiedStartAsync() {
  const classifiedToRemove = yield select(getClassified);
  try {
    const classified = yield call(removeClassified, classifiedToRemove.id);
    yield put(removeClassifiedSuccess(classified));
  } catch (error) {
    yield put(removeClassifiedFailure(error.message));
  }
}

export function* createClassifiedStart() {
  yield takeLatest(CREATE_CLASSIFIED_START, createClassifiedStartAsync);
}

export function* createClassifiedStartAsync() {
  const classifiedToCerate = yield select(getClassifiedToCreate);
  const user = yield select(getUser);
  try {
    const classified = yield call(createClassified, user, classifiedToCerate);
    yield put(createClassifiedSuccess(classified));
  } catch (error) {
    yield put(createClassifiedFailure(error.message));
  }
}

export function* fetchClassifiedStart() {
  yield takeLatest(FETCH_CLASSIFIED_START, fetchClassifiedStartAsync);
}

export function* fetchClassifiedStartAsync() {
  const classified = yield select(getClassified);
  try {
    const classifieds = yield call(fetchClassified, classified.id);
    yield put(fetchClassifiedSuccess(classifieds));
  } catch (error) {
    yield put(fetchClassifiedFailure(error.message));
  }
}

export function* fetchLastClassifiedStart() {
  yield takeLatest(FETCH_LAST_CLASSIFIED_START, fetchLastClassifiedStartAsync);
}

export function* fetchLastClassifiedStartAsync() {
  const classified = yield select(getClassified);
  try {
    const classifieds = yield call(fetchLastClassified, classified.uid);
    yield put(fetchLastClassifiedSuccess(classifieds));
  } catch (error) {
    yield put(fetchLastClassifiedFailure(error.message));
  }
}

export function* fetchUserClassifiedsStart() {
  yield takeLatest(FETCH_USER_CLASSIFIED_START, fetchUserClassifiedsStartAsync);
}

export function* fetchUserClassifiedsStartAsync() {
  const uid = yield select(getUID);
  try {
    const classifieds = yield call(fetchUserClassifieds, uid);
    yield put(fetchUserClassifiedsSuccess(classifieds));
  } catch (error) {
    yield put(fetchUserClassifiedsFailure(error.message));
  }
}

export function* classifiedSagas() {
  yield all([
    call(createClassifiedStart),
    call(fetchUserClassifiedsStart),
    call(fetchClassifiedStart),
    call(fetchLastClassifiedStart),
    call(removeClassifiedStart),
    call(registerClassifiedStart),
    call(fetchClassifiedByStatusStart),
    call(setClassifiedStatusStart),
  ]);
}

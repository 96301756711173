import { formatPhoneNumber } from "react-phone-number-input/input";

import { classifiedTypes } from "../redux/classified/types";

const ClassifiedCard = ({ classified, preview, onClick }) => {
  if (!classified) return;

  const { content, email, type, phoneNumber1, phoneNumber2 } = classified;

  return (
    <div tabIndex={0} onClick={onClick}>
      <div
        className={`grid grid-cols-3 w-ful ${classifiedTypes[type].textColor}  ${classifiedTypes[type].color} text-center  rounded-t`}
      >
        <div></div>
        <div
          className={`text-2xl font-bold py-2 text-center  whitespace-nowrap	`}
        >
          {classifiedTypes[type].name}
        </div>
      </div>
      <div
        className="rounded border  w-full min-h-[full] bg-white animatecss animatecss-fadeIn  "
        style={{
          backgroundImage: preview
            ? `url("https://firebasestorage.googleapis.com/v0/b/iranjavan.appspot.com/o/preview.jpg?alt=media&token=be975632-3262-4626-91a9-72c5529859b0")`
            : null,
          backgroundSize: "20%",
        }}
      >
        <hr />
        <div
          className={`text-center break-words text-sm lg:text-lg leading-8 pt-5 px-5 text-wrap	 ${
            preview ? "text-black" : "text-neutral-600"
          }`}
        >
          <div
            className="flex items-center justify-center h-96 overflow-auto"
            dangerouslySetInnerHTML={{
              __html: `${content.replaceAll("\n", `<br/>`)}`,
            }}
          />
        </div>
        <div className="text-center text-gray-800 text-xl font-bold h-7">
          {phoneNumber1 || phoneNumber2 ? email : ""}
        </div>
      </div>
      {(phoneNumber1 || phoneNumber2) && (
        <div
          className={`grid  ${
            phoneNumber1 && phoneNumber2 ? "grid-cols-2" : "grid-cols-1"
          } rounded-b p-2 
        ${classifiedTypes[type].textColor}  ${classifiedTypes[type].color}`}
        >
          <div
            className={`text-xl xl:text-xl  font-black   whitespace-nowrap  text-center`}
            style={{ direction: "ltr" }}
          >
            {formatPhoneNumber(phoneNumber1)}
          </div>
          <div
            className={`text-xl xl:text-xl font-black   whitespace-nowrap ${classifiedTypes[type].textColor}  ${classifiedTypes[type].color} text-center`}
            style={{ direction: "ltr" }}
          >
            {formatPhoneNumber(phoneNumber2)}
          </div>
        </div>
      )}

      {!phoneNumber1 && !phoneNumber2 && email && (
        <div
          className={`grid rounded-b p-2 ${classifiedTypes[type].textColor}  ${classifiedTypes[type].color}`}
        >
          <div
            className={`text-xl xl:text-2xl  font-black   whitespace-nowrap  text-center`}
            style={{ direction: "ltr" }}
          >
            {email}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassifiedCard;

import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import ClassifiedCard from "../components/ClassifiedCard";
import { formatPhoneNumber } from "react-phone-number-input";
import { classifiedStatus } from "../redux/classified/types";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { setClassifiedStatusStart } from "../redux/classified/actions";
import { useEffect } from "react";

const OrderDetails = () => {
  const { classifiedId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mySwal = withReactContent(Swal);

  const classified = useSelector(
    (state) => state.classified.list.filter((c) => c.id === classifiedId)[0]
  );

  const isConfirmed = useSelector((state) => state.classified.confirmed);

  const user = useSelector((state) => state.user);

  const HST = () => {
    return parseFloat((calculatePrice() * 13) / 100).toFixed(2);
  };

  const phonePricing = () => {
    const nf = classified.phoneNumber1 && classified.phoneNumber2 ? 1 : 0;
    let tnf = wordCountPricing() * nf;
    if (classified.email) tnf += 5;
    return tnf;
  };

  const wordCountPricing = () => {
    let price = 0;
    if (classified.wordCount <= 15) price += 10;
    if (classified.wordCount > 15 && classified.wordCount <= 30) price += 20;
    if (classified.wordCount > 31 && classified.wordCount <= 50) price += 30;
    if (classified.wordCount > 51 && classified.wordCount <= 80) price += 60;
    return price;
  };

  useEffect(() => {
    if (isConfirmed) navigate("/admin/dashboard");
  }, [isConfirmed, navigate]);

  const durationPricing = () => {
    return (wordCountPricing() + phonePricing()) * (classified.duration - 1);
  };

  const calculatePrice = () => {
    let price = wordCountPricing() + phonePricing() + durationPricing();
    return price;
  };

  const handleConfirmClassifiedAndPrint = () => {
    mySwal
      .fire({
        didOpen: () => {
          mySwal.clickConfirm();
        },
      })
      .then(async () => {
        const result = await mySwal.fire({
          title: "تایید آگهی و ارسال برای نشر / چاپ",
          html: `<p>مشتری از تصمیم شما مطلع خواهد شد، آیا مطمئنید؟</p>`,
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "بله مطمئنم، تایید شود",
          cancelButtonColor: "#aaa",
          cancelButtonText: "نه مطمئن نیستم",
          confirmButtonColor: "#22C55E",
          reverseButtons: true,
        });
        if (result.isConfirmed) {
          dispatch(setClassifiedStatusStart(6, classified.id));
        }
      });
  };

  const handleRejectClassified = () => {
    mySwal
      .fire({
        didOpen: () => {
          mySwal.clickConfirm();
        },
      })
      .then(async () => {
        const result = await mySwal.fire({
          title: "ریجکت و عدم تایید آگهی",
          html: `<p>به مشتری اطلاع داده خواهد شد که با پشتیبانی تماس بگیرد</p>`,
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "بله مطمئنم، ریجکت شود",
          cancelButtonColor: "gray",
          cancelButtonText: "نه مطمئن نیستم",
          confirmButtonColor: "red",
          reverseButtons: true,
        });
        if (result.isConfirmed) {
          dispatch(setClassifiedStatusStart(7, classified.id));
        }
      });
  };

  const capture =
    classified.payment.paypal.purchase_units[0].payments.captures[0];

  if (classified?.id !== classifiedId)
    return <div className="py-20">Not found</div>;

  return classified && !classified.isFetching ? (
    <div
      className="w-full xl:w-10/12 2xl:w-10/12 px-5 py-12"
      style={{ direction: "rtl" }}
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 ">
        <div>
          <h1 className="text-3xl mt-2 py-6 font-bold text-center md:text-right">
            اطلاعات آگهی
          </h1>
        </div>
        <div className="text-center lg:text-left">
          {user.profile.role === "endUser" && (
            <button
              type="button"
              className="hover:bg-gray-700 bg-black hover:text-gray-100 my-6 w-full md:w-80
                              font-semibold text-white py-2 px-2 border border-gray-700 hover:border-transparent rounded"
              onClick={() => navigate(`/invoice/${classifiedId}`)}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 inline-block"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
              </span>
              <span className="mx-2"> دریافت نسخه انگلیسی صورتحساب </span>
            </button>
          )}
        </div>
      </div>
      <hr />
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-2">
        <div className="mt-5 ">
          <div className="shadow-xl">
            <ClassifiedCard classified={classified} />
          </div>
        </div>
        <div className="col-span-2 px-0 lg:pr-5 mt-0 lg:mt-5  lg:order-last">
          <table className="table-auto  w-full font-normal lg:text-xl mt-10 lg:mt-0 shadow border">
            <tbody>
              <tr>
                <td className="border text-center bg-gray-100 p-3" colSpan={2}>
                  مشخصات آگهی
                </td>
              </tr>
              <tr>
                <td className="border p-2 w-3/5 px-5 ">شناسه</td>
                <td className="text-xs md:text-xl text-center border">
                  {classified.id}
                </td>
              </tr>
              <tr>
                <td className="border p-2 px-5">وضعیت سفارش</td>
                <td className="border text-center">
                  <span
                    className={`border ${
                      classified.status === 7
                        ? "bg-red-200 text-red-800"
                        : "bg-green-100 text-green-800"
                    } rounded text-xs py-1 lg:py-0 lg:text-lg font-semibold text-green-800  px-2`}
                  >
                    {classifiedStatus[classified.status]}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="text-right p-2 px-5 border">
                  تعداد کلمات : {classified.wordCount}
                </td>
                <td className="text-right px-10 border ">
                  ${wordCountPricing()}
                </td>
              </tr>
              <tr>
                <td className="text-right  px-5 p-2 border">
                  تعداد شماره تلفن :{" "}
                  {classified.phoneNumber1 && classified.phoneNumber2 ? 2 : 1}
                </td>
                <td className="text-right px-10 p-2 border ">
                  $
                  {classified.phoneNumber1 && classified.phoneNumber2
                    ? wordCountPricing()
                    : 0}
                </td>
              </tr>
              <tr>
                <td className="text-right  px-5 p-2 border">هزینه درج ایمیل:</td>
                <td className="text-right px-10 p-2 border ">
                  {classified.email ? "$5" : "$0"}
                </td>
              </tr>
              <tr>
                <td className="text-right px-5 p-2 border">
                  تعداد چاپ ( هفته) : {classified.duration}
                </td>
                <td className="text-right px-10 p-2 border ">
                  ${durationPricing()}
                </td>
              </tr>
              <tr>
                <td className="text-right px-5 p-2 border ">HST</td>
                <td className="text-right px-10 p-2 border ">${HST()}</td>
              </tr>
              <tr>
                <td className="text-right p-2 px-5 border">مبلغ قابل پرداخت</td>
                <td className="text-right px-10 p-2 border ">
                  {" "}
                  $
                  {(parseFloat(calculatePrice()) + parseFloat(HST())).toFixed(
                    2
                  )}
                </td>
              </tr>
              <tr>
                <td className="border text-center bg-gray-100 p-2" colSpan={2}>
                  اطلاعات پرداخت
                </td>
              </tr>
              <tr>
                <td className="border p-2 px-5">شناسه کاربر</td>
                <td
                  className="border text-center text-xs md:txt-xl"
                  style={{ direction: "ltr" }}
                >
                  {classified.profile.id}
                </td>
              </tr>
              <tr>
                <td className="border p-2 px-5">نام کاربر</td>
                <td
                  className="border text-center text-md md:txt-xl"
                  style={{ direction: "ltr" }}
                >
                  {classified.profile.firstName} {classified.profile.lastName}
                </td>
              </tr>
              <tr>
                <td className="border p-2 px-5">شماره تلفن کاربری</td>
                <td
                  className="border text-center text-md md:txt-xl"
                  style={{ direction: "ltr" }}
                >
                  {formatPhoneNumber(classified.profile.phoneNumber)} 
                </td>
              </tr>

              <tr>
                <td className="border p-2 px-5">شناسه پرداخت</td>
                <td
                  className="border text-center text-xs md:txt-xl"
                  style={{ direction: "ltr" }}
                >
                  {capture.id}
                </td>
              </tr>
              <tr>
                <td className="border p-2 px-5">تاریخ پرداخت</td>
                <td className="border text-center" style={{ direction: "ltr" }}>
                  {new Date(capture.update_time).toLocaleDateString("en-CA")}
                </td>
              </tr>
              <tr>
                <td className="border p-2 px-5">مبلغ پرداختی</td>
                <td className="border text-center" style={{ direction: "ltr" }}>
                  {`$${capture.amount.value}
                 ${capture.amount.currency_code}`}
                </td>
              </tr>
            </tbody>
          </table>
          {user.profile.role === "admin" && (
            <div className="flex flex-col lg:flex-row gap-2 mt-10 float-none lg:float-left">
              <div className="text-right">
                <button
                  type="button"
                  className="hover:bg-gray-700 bg-black hover:text-gray-100 w-full md:w-60
                              font-semibold text-white py-2 px-2 border border-gray-700 hover:border-transparent rounded
                              disabled:bg-gray-400 disabled:border-gray-400 disabled:cursor-not-allowed"
                  onClick={handleConfirmClassifiedAndPrint}
                  disabled={classified.status === 6}
                >
                  تایید و ارسال برای چاپ
                </button>
              </div>
              <div className="border">
                <button
                  type="button"
                  className="hover:bg-red-700 bg-red-600 hover:text-gray-100 w-full md:w-60
                              font-semibold text-white py-2 px-2 border border-red-700 hover:border-transparent rounded
                              disabled:bg-gray-400 disabled:border-gray-400 disabled:cursor-not-allowed"
                  disabled={classified.status === 7}
                  onClick={handleRejectClassified}
                >
                  عدم تایید
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default OrderDetails;

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { saveUserProfileStart } from "../redux/user/actions";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [canSave, setCanSave] = useState(false);
  const [agreement, setAgreement] = useState(true);
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const user = useSelector((state) => state.user);
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  useEffect(() => {
    if (user.profile) setProfile(user.profile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, user]);

  useEffect(() => {
    setCanSave(JSON.stringify(profile) !== JSON.stringify(user.profile));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (!user.profile?.status)
      setError(
        'تکمیل ثبت نام : لطفا اطلاعات خورد را وارد کنید و روی دکمه "ذخیره پروفایل" کلیک کنید.'
      );
  }, [user.profile?.status]);

  const handleSaveBtnClick = () => {
    if (!profile.firstName || !profile.lastName)
      return setError("لطفا اطلاعات را کامل وارد کنید");
    if (!validateEmail(profile.email))
      return setError("لطفا یک ایمیل معتبر وارد کنید");
    dispatch(
      saveUserProfileStart({
        ...profile,
        role: "endUser",
        phoneNumber: user.phoneNumber,
      })
    );
    setError("");
    setCanSave(false);
  };

  return (
    user.profile && (
      <div>
        <div className="flex flex-col items-start pt-12 min-h-screen animatecss animatecss-fadeIn">
          <div
            className="w-full xl:w-10/12 2xl:w-10/12 px-5"
            style={{ direction: "rtl" }}
          >
            {error && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-10"
                role="alert"
              >
                <span className="block sm:inline text-sm lg:text-lg">
                  {error}
                </span>
                <span className="absolute top-0 bottom-0 left-0 px-4 py-3"></span>
              </div>
            )}

            <div>
              <div className="grid grid-cols-1 lg:grid-cols-2">
                <div>
                  <h1 className="text-3xl mt-2 py-6 font-bold text-center md:text-right">
                    پروفایل کاربری
                    <span className="text-sm block lg:inline lg:mt-0 mt-2">
                      ( لطفا به انگلیسی وارد کنید )
                    </span>
                  </h1>
                </div>
                <div className="text-center lg:text-left">
                  {user.profile.status ? (
                    <div>
                      <button
                        type="button"
                        className="hover:bg-gray-700 bg-black hover:text-gray-100 my-6 w-full md:w-72
                              font-semibold text-white py-2 px-2 border border-gray-700 hover:border-transparent rounded"
                        onClick={() => navigate("/register-classified")}
                      >
                        <span className="text-xl">+</span>
                        <span className="mx-2">
                          ثبت آگهی در نیازمندیهای ایران جوان
                        </span>
                      </button>
                    </div>
                  ):null}
                </div>
              </div>
            </div>
            <hr />
            <div className="mt-5">
              <form
                className="flex flex-col w-full lg:w-1/2 mx-auto"
                style={{ direction: "ltr" }}
              >
                <label className="text-sm text-gray-800">Phone Number</label>
                <span
                  disabled
                  className="mt-1 appearance-none bg-gray-300  relative block w-full lg:w-1/2 px-4 pt-3 pb-2 border border-gray-300 
                         mb-5 text-2xl rounded-md"
                >
                  {user.phoneNumber}
                </span>
                <label className="text-sm text-gray-800">First Name:</label>
                <input
                  value={profile.firstName}
                  className="mt-1 appearance-none  relative block w-full   px-4 py-3 border border-gray-300 
                        placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-200 
                        focus:border-indigo-200 focus:z-10 text-2xl mb-5"
                  onChange={(e) =>
                    setProfile({ ...profile, firstName: e.target.value })
                  }
                />

                <label className="text-sm text-gray-800">last Name:</label>
                <input
                  value={profile.lastName}
                  className="mt-1 appearance-none  relative block w-full  px-4 py-3 border border-gray-300 
                        placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-200 
                        focus:border-indigo-200 focus:z-10 text-2xl mb-5"
                  onChange={(e) =>
                    setProfile({ ...profile, lastName: e.target.value })
                  }
                />

                <label className="text-sm text-gray-800">Email Address</label>
                <input
                  value={profile.email}
                  className="mt-1 appearance-none  relative block w-full   px-4 py-3 border border-gray-300 
                        placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-200 
                        focus:border-indigo-200 focus:z-10 text-2xl mb-5"
                  onChange={(e) =>
                    setProfile({ ...profile, email: e.target.value })
                  }
                />
                <div>
                  <input
                    className="form-check-input h-6 w-6 lg:h-5 lg:w-5 border-gray-300  checked:bg-blue-600 checked:border-blue-600 focus:outline-none 
                  transition duration-200 mt-0 lg:mt-1 align-top float-right ml-1 cursor-pointer"
                    type="checkbox"
                    checked={agreement}
                    disabled={!canSave}
                    id="flexCheckDefault"
                    onChange={() => setAgreement(!agreement)}
                  />
                  <div
                    className="form-check-label text-right text-md md:text-lg text-gray-800"
                    htmlFor="flexCheckDefault"
                  >
                    من تایید می کنم که
                    <button
                      className="text-blue-700 font-bold px-2 "
                      onClick={() => navigate("/terms-of-use")}
                    >
                      قوانین سایت
                    </button>
                    و
                    <button
                      className="text-blue-700 font-bold px-2"
                      onClick={() => navigate("/privacy-policy")}
                    >
                      سیاست حفظ حریم خصوصی
                    </button>
                      ایران جوان را مطالعه کرده ام و با مفاد آنها موافقم
                  </div>
                </div>
                <div className="text-right pb-10 mt-5">
                  <button
                    className="text-center w-full lg:w-80 px-20 py-4 bg-gray-700 text-white rounded-md text-xl
                                         hover:bg-black  duration-300 ease-in disabled:bg-gray-300 
                                          disabled:text-gray-600 disabled:cursor-not-allowed"
                    type="button"
                    disabled={!canSave || !agreement}
                    onClick={handleSaveBtnClick}
                  >
                    ذخیره پروفایل
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Profile;

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Spinner from "../components/Spinner";
import ClassifiedCard from "../components/ClassifiedCard";

import PaypalCheckoutButton from "../components/PayPalCheckoutButton";

import {
  fetchLastClassifiedStart,
  removeClassifiedStart,
} from "../redux/classified/actions";

const PayClassified = () => {
  const { classifiedId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mySwal = withReactContent(Swal);

  const [agreement, setAgreement] = useState(true);

  const phonePricing = () => {
    const nf = classified.phoneNumber1 && classified.phoneNumber2 ? 1 : 0;
    let tnf = wordCountPricing() * nf;
    if (classified.email) tnf += 5;
    return tnf;
  };

  const HST = () => {
    return parseFloat((calculatePrice() * 13) / 100).toFixed(2);
  };

  const wordCountPricing = () => {
    let price = 0;
    if (classified.wordCount <= 15) price += 10;
    if (classified.wordCount > 15 && classified.wordCount <= 30) price += 20;
    if (classified.wordCount > 30 && classified.wordCount <= 50) price += 30;
    if (classified.wordCount > 50 && classified.wordCount <= 80) price += 60;
    console.log(price)
    return price;
  };

  const durationPricing = () => {
    return (wordCountPricing() + phonePricing()) * (classified.duration - 1);
  };

  const calculatePrice = () => {
    let price = wordCountPricing() + phonePricing() + durationPricing();
    return price;
  };

  const classified = useSelector((state) => state.classified);
  const user = useSelector((state) => state.user);

  const numberOfPhoneUsed =
    classified.phoneNumber1 && classified.phoneNumber2 ? 2 : 1;

  useEffect(() => {
    if (user.uid) dispatch(fetchLastClassifiedStart(user.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (classified.status !== 1 || !classified.status)
      navigate("/register-classified");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classified.status]);

  const handleCancelButton = () => {
    mySwal
      .fire({
        didOpen: () => {
          mySwal.clickConfirm();
        },
      })
      .then(async () => {
        const result = await mySwal.fire({
          title: "انصراف از ثبت آگهی",
          html: `<p>در صورت انصراف از ثبت آگهی اطلاعات مربوط به این آگهی حذف خواهد شد، آیا مطمئنید؟</p>`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "بله مطمئنم، حذف  شود",
          confirmButtonColor: "#B91C1C",
          cancelButtonText: "نه مطمئن نیستم",
          cancelButtonColor: "#22C55E",
          reverseButtons: true,
        });
        if (result.isConfirmed) {
          dispatch(removeClassifiedStart(classifiedId));
        }
      });
  };

  return !classified.isFetching && classified.id ? (
    <div>
      <div className="w-full xl:w-10/12 2xl:w-10/12 flex flex-col items-end  min-h-screen py-28  px-5  animatecss  animatecss-fadeIn">
        <div className="w-full">
          <div className="text-center md:text-left mb-5">
            <h1 className="text-3xl pb-6 font-bold text-center md:text-right">
              پیش نمایش و پرداخت
            </h1>
            <hr />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-5 w-full">
          <div className="w-full" style={{ direction: "rtl" }}>
            <ClassifiedCard classified={classified} preview />
          </div>
          <div className="text-left col-span-2">
            <table className="table-auto pl-5 w-full text-sm lg:text-2xl mt-10 lg:mt-0">
              <tbody>
                <tr>
                  <td className="text-center p-5 border ">
                    ${wordCountPricing()}
                  </td>
                  <td className="text-right p-5 border w-2/3 xl:w-1/2 bg-gray-100 hover:bg-blue-100 hover:bg-blue-100">
                    {classified.wordCount} : تعداد کلمات
                  </td>
                </tr>
                <tr>
                  <td className="text-center p-5 border ">
                    {" "}
                    $
                    {classified.phoneNumber1 && classified.phoneNumber2
                      ? wordCountPricing()
                      : 0}
                  </td>
                  <td className="text-right p-5 border bg-gray-100 hover:bg-blue-100">
                    {numberOfPhoneUsed} : تعداد شماره تلفن
                  </td>
                </tr>
                <tr>
                  <td className="text-center p-5 border ">
                    {classified.email ? "$5" : "$0"}
                  </td>
                  <td className="text-right p-5 border bg-gray-100 hover:bg-blue-100">
                    : هزینه ایمیل
                  </td>
                </tr>
                <tr>
                  <td className="text-center p-5 border ">
                    ${durationPricing()}
                  </td>
                  <td className="text-right p-5 border bg-gray-100 hover:bg-blue-100">
                    {classified.duration} : تعداد نوبت چاپ ( هفته)
                  </td>
                </tr>
                <tr>
                  <td className="text-center p-5 border ">${HST()}</td>
                  <td className="text-right p-5 border bg-gray-100 hover:bg-blue-100">
                    HST
                  </td>
                </tr>
                <tr>
                  <td className="text-center p-5 border ">
                    $
                    {(parseFloat(calculatePrice()) + parseFloat(HST())).toFixed(
                      2
                    )}
                  </td>
                  <td className="text-right p-5 border bg-gray-100 hover:bg-blue-100">
                    مبلغ قابل پرداخت
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    className="text-right text-xl pt-10"
                    style={{ direction: "rtl" }}
                  ></td>
                </tr>
              </tbody>
            </table>
            <div className="text-right text-sm form-check" direction="rtl">
              <input
                className="form-check-input h-6 w-6 lg:h-5 lg:w-5 border-gray-300  checked:bg-blue-600 checked:border-blue-600 focus:outline-none 
                  transition duration-200 mt-0 lg:mt-1 align-top float-right ml-1 cursor-pointer"
                type="checkbox"
                checked={agreement}
                id="flexCheckDefault"
                onChange={() => setAgreement(!agreement)}
              />
              <label
                className="form-check-label text-right text-md md:text-lg text-gray-800"
                htmlFor="flexCheckDefault"
              >
                با انجام پرداخت من تایید می کنم که
                <button
                  className="text-blue-700 font-bold px-2 "
                  onClick={() => navigate("/terms-of-use")}
                >
                  قوانین سایت
                </button>
                و
                <button
                  className="text-blue-700 font-bold px-2"
                  onClick={() => navigate("/privacy-policy")}
                >
                  سیاست حفظ حریم خصوصی
                </button>
                ایران جوان را مطالعه کرده ام و مطلع هستم که با توجه به ماهیت
                خدماتی که ارائه خواهد شد امکان بازپرداخت مبلغ پرداخت شده وجود
                ندارد
              </label>
              <p className="mt-5">
                <span className="text-3xl font-bold text-red-500">
                  خیلی مهم:{" "}
                </span>
                آگهی نمایش داده شده در این صفحه و همچنین وب سایت با آنچه در مجله
                و یا وب سایت و اپلیکیشن نشر خواهد شد، از لحاظ رنگ و سایز و نوع
                فونت ممکن است متفاوت باشد
              </p>
            </div>
          </div>
        </div>
        <div className="w-full py-5 text-right text-xl">
          <hr />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-5 w-full gap-0 lg:gap-5">
          <div className="col-span-3"></div>
          <div className="order-first lg:order-last	paypal-button-container w-full text-center ">
            <PaypalCheckoutButton
              agreed={agreement}
              product={{
                description: "Iran Javan Classified AD",
                price: parseFloat(calculatePrice()) + parseFloat(HST()),
              }}
              cost={{
                numberOfPhoneUsed,
              }}
            />
          </div>
          <div>
            <button
              type="button"
              className="hover:bg-red-700 bg-white hover:text-gray-100  w-full mt-5 lg:mt-0 text-xl
                              font-semibold text-gray-700 py-3 px-2 border border-gray-600 hover:border-transparent rounded"
              style={{ height: 53 }}
              onClick={handleCancelButton}
            >
              <span className="mx-2">انصراف و حذف</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default PayClassified;
